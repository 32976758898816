// useful for admin purpose, referenced in form.html for the moment.

//TODO: if is to publish for access do ::varchar(255) for each string field.

import expr from './expressionEvaluation.js'
import moment from 'moment'
import utils_gen from './utils_gen.js'

const replaceAll = utils_gen.replaceAll

export default{
  createViewSql(form_id,formDefinition,options){
    let sql=[]



    // search_path:
    let schemas = 'public'
    if (options['schemas']){
      schemas = options['schemas_val']
    }
    if (options['postgis_schema']){
      schemas += ',postgis'
    }
    sql.push(`set search_path = ${schemas};`)
    // set this value as we will use it to ensure we put everything in the right schema.
    // schemas_val could have more than one value, so ensure we only have one.
    options['schema_val'] = schemas.split(',')[0].trim()

    // ****** functions used:`
    if(options.numbersErrors){
      sql.push(`
        CREATE OR REPLACE FUNCTION ${options.schema_val}.convert_to_int(text)
          RETURNS int AS
        $func$
        BEGIN
          RETURN $1::float::int;
        EXCEPTION WHEN OTHERS THEN
          RETURN NULL;
        END
        $func$  LANGUAGE plpgsql IMMUTABLE;

        CREATE OR REPLACE FUNCTION ${options.schema_val}.convert_to_float(text)
          RETURNS float AS
        $func$
        BEGIN
          RETURN $1::float;
        EXCEPTION WHEN OTHERS THEN
          RETURN NULL; 
        END
        $func$  LANGUAGE plpgsql IMMUTABLE;
      `)
      const f1 = [(`${options.schema_val}.convert_to_int`), (`${options.schema_val}.convert_to_float`)]
      f1.forEach(y=>{
        options['grantUsers_val']?.split(',')?.forEach(x=>{
          sql.push(`GRANT EXECUTE ON FUNCTION ${y} to ${x};`)
        })
      })
    }
    // to avoid the "ERROR: cannot extract elements from a scalar ", usually null values
    sql.push(` 
      CREATE OR REPLACE FUNCTION ${options.schema_val}.jsonb_array_elements_text_2(jsonb)
        RETURNS setof text AS
      $func$
      BEGIN
        return query select jsonb_array_elements_text($1);
      EXCEPTION WHEN OTHERS THEN
        return; 
      END;
      $func$  LANGUAGE plpgsql IMMUTABLE;

      CREATE OR REPLACE FUNCTION ${options.schema_val}.ST_geomFromText_2(text)
        RETURNS geometry AS
      $func$
      BEGIN
        return ST_geomFromText($1);
      EXCEPTION WHEN OTHERS THEN
        return NULL; 
      END;
      $func$  LANGUAGE plpgsql IMMUTABLE;

      CREATE OR REPLACE FUNCTION ${options.schema_val}.ST_X_2(text)
        RETURNS float AS
      $func$
      BEGIN
        return ST_X($1);
      EXCEPTION WHEN OTHERS THEN
        return NULL; 
      END;
      $func$  LANGUAGE plpgsql IMMUTABLE;

      CREATE OR REPLACE FUNCTION ${options.schema_val}.ST_Y_2(text)
        RETURNS float AS
      $func$
      BEGIN
        return ST_Y($1);
      EXCEPTION WHEN OTHERS THEN
        return NULL; 
      END;
      $func$  LANGUAGE plpgsql IMMUTABLE;

      CREATE OR REPLACE FUNCTION ${options.schema_val}.photo_${form_id}(text)
        RETURNS text AS
      $func$
      BEGIN
        return '${options.couchDbUrl + form_id + '/'}' || $1 || '/photo';
      EXCEPTION WHEN OTHERS THEN
        return NULL; 
      END;
      $func$  LANGUAGE plpgsql IMMUTABLE;
      `
    )
    const f1 = [
      `${options.schema_val}.jsonb_array_elements_text_2`,
      `${options.schema_val}.ST_geomFromText_2`,
      `${options.schema_val}.ST_X_2`,
      `${options.schema_val}.ST_Y_2`,
      `${options.schema_val}.photo_${form_id}`,
    ]
    f1.forEach(y=>{
      options['grantUsers_val']?.split(',')?.forEach(x=>{
        sql.push(`GRANT EXECUTE ON FUNCTION ${y} to ${x};`)
      })
    })
    let otherSQL={
      choices:[],
    }
    if(options.exportChoices || options.showLabelValue){
      otherSQL.choices.push(`
        DROP TABLE IF EXISTS ${options.schema_val}.${options.v_name}_choices;
        CREATE TABLE ${options.schema_val}.${options.v_name}_choices (
          list_name text,
          value text,
          label text
        );
        create index on ${options.schema_val}.${options.v_name}_choices(list_name,value);
        `)
      options['grantUsers_val']?.split(',')?.forEach(x=>{
        otherSQL.choices.push(`grant select on ${options.schema_val}.${options.v_name}_choices to ${x};`)
      })
      otherSQL.choices.push(`
        INSERT INTO ${options.schema_val}.${options.v_name}_choices VALUES
      `)

      // The function must be craeted first, as it's used in the view.
      sql.push(`
        CREATE OR REPLACE FUNCTION ${options.schema_val}.getChoices_${form_id}(text,text)
            RETURNS text AS
          $func$
          BEGIN
            return (SELECT label from ${options.schema_val}.${options.v_name}_choices where list_name=$1 and value=$2) ;
          EXCEPTION WHEN OTHERS THEN
            return NULL; 
          END;
          $func$  LANGUAGE plpgsql IMMUTABLE;
      `)
      options['grantUsers_val']?.split(',')?.forEach(x=>{
        sql.push(`GRANT EXECUTE ON FUNCTION ${options.schema_val}.getChoices_${form_id} to ${x};`)
      })
    }
    // Export the choices list - done in each exported fields

    // *** Create the views
    sql.push(`DROP ${options.metrializedView?'MATERIALIZED':''} VIEW IF EXISTS ${options.schema_val}.${options.v_name} CASCADE;`)
    sql.push(`/* SELECT * from ${options.v_name}; */`)//for testing.
    sql.push(`CREATE ${options.metrializedView?'MATERIALIZED':''} VIEW ${options.schema_val}.${options.v_name} as`)
    sql.push('SELECT ')
    //sql.push("doc->>'_id' doc_id,")
    sql.push(this.getDocColumn(options))
    sql.push(',')
    options['splitNbColumns_iBeginFields'] = sql.length
    if(formDefinition.sql_add){
      sql.push(formDefinition.sql_add) //custom fields as id_rem
    }
    if(options.varchar){
      sql.push("(doc->>'creator')::varchar(255) AS creator,")
      sql.push("(doc->>'device_id')::varchar(255) AS device_id,")
      sql.push("(doc->>'creationTime')::varchar(255) AS creationTime,")
      sql.push("(doc->>'editor')::varchar(255) AS editor,")
      sql.push("(doc->>'editor_device_id')::varchar(255) AS editor_device_id,")
      sql.push("(doc->>'editor_time')::varchar(255) AS editor_time,")
    }else{
      sql.push("(doc->>'creator') AS creator,")
      sql.push("(doc->>'device_id') AS device_id,")
      sql.push("(doc->>'creationTime') AS creationTime,")
      sql.push("(doc->>'editor') AS editor,")
      sql.push("(doc->>'editor_device_id') AS editor_device_id,")
      sql.push("(doc->>'editor_time') AS editor_time,")
    }
    sql.push("(doc->>'form_status_global')::integer AS form_status_global,")
    sql.push("(doc->>'survey_deleted')::boolean AS survey_deleted,")
    sql.push("(doc->>'survey_valid')::boolean AS survey_valid,")
    let repeatSql=[]
    this.createViewSql_sub(formDefinition.children,formDefinition,sql,repeatSql,['form_data'],form_id,options,form_id,[],otherSQL)
    sql.pop()// remove last comma
    if (options.splitNbColumns){
      let iTable = 0
      let newTable = true
      let nbFields = 0
      let maxFields = +options['splitNbColumns_val']
      

      for (let i = options['splitNbColumns_iBeginFields']; i < sql.length; i++) {
        if(newTable){
          iTable += 1
          otherSQL[`${options.v_name}_${iTable}`]=[]
          otherSQL[`${options.v_name}_${iTable}`].push(`DROP ${options.metrializedView?'MATERIALIZED':''} VIEW IF EXISTS ${options.schema_val}.${options.v_name}_${iTable} CASCADE;`)
          otherSQL[`${options.v_name}_${iTable}`].push(`/* SELECT * from ${options.v_name}_${iTable}; */`)//for testing.
          otherSQL[`${options.v_name}_${iTable}`].push(`CREATE ${options.metrializedView?'MATERIALIZED':''} VIEW ${options.schema_val}.${options.v_name}_${iTable} as`)
          otherSQL[`${options.v_name}_${iTable}`].push('SELECT ')
          otherSQL[`${options.v_name}_${iTable}`].push(this.getDocColumn(options))
          otherSQL[`${options.v_name}_${iTable}`].push(',')
          newTable=false
        }
        if(sql[i]!=','){
          nbFields += 1
        }else if( nbFields >= maxFields){
          // it's a comma and we don'T want to add more field
          newTable = true
        }
        if(newTable || i == (sql.length-1)){ // new table or the last field
          if(otherSQL[`${options.v_name}_${iTable}`][otherSQL[`${options.v_name}_${iTable}`].length-1]==','){
            otherSQL[`${options.v_name}_${iTable}`].pop()
          }
          otherSQL[`${options.v_name}_${iTable}`].push('FROM '+form_id )
          otherSQL[`${options.v_name}_${iTable}`].push("WHERE id LIKE 'survey_%'")
          if (!options.includeDeletedSurveys){
            otherSQL[`${options.v_name}_${iTable}`].push(" and COALESCE((doc->'survey_deleted')::bool, false)=false")
          }
          otherSQL[`${options.v_name}_${iTable}`].push(';')
          nbFields = 1
        }else{
          otherSQL[`${options.v_name}_${iTable}`].push(sql[i])
        }
        
      }
    }
    sql.push('FROM '+form_id )
    sql.push("WHERE id LIKE 'survey_%'")
    if (!options.includeDeletedSurveys){
      sql.push(" and COALESCE((doc->'survey_deleted')::bool, false)=false")
    }
    sql.push(';')
    //  add grant:
    options['grantUsers_val']?.split(',')?.forEach(x=>{
      sql.push(`grant select on ${options.schema_val}.${options.v_name} to ${x};`)
    })
    // ** Build repeat SQL
    let sqlRepeat=''
    repeatSql.map(x=>{
      sqlRepeat+=x.join(' ')
    })

    // ** Finish choices SQL
    if(options.exportChoices || options.showLabelValue){
      otherSQL.choices.pop()// remove last comma
      otherSQL.choices.push(';')
    }
    
    // ** Build final SQL
    return sql.join(' ') + sqlRepeat + Object.keys(otherSQL).map(x=> otherSQL[x].join(' ')).join(' ')
  },
  createViewSql_sub(children,formDefinition,sql,repeatSql,namePath,form_id,options,from_definition, doneFieldsName, otherSQL){
    let textFieldCast = '::text'
    if(options.varchar){
      //want varchar - better for MSaccess
      textFieldCast = '::varchar(255)'
    }
    // let doneFieldsName = [] // holds the list done, if reapeated, add a suffix
    //create the fields for the childs.
    //from_definition holds a string that is used in the from clauses below. allow for sub repeat
    children.map(x=>{
      //todo: get the default value
      
      //should always have a name if we have children.
      if(x.type=='repeat' || (x.type=='select all that apply' && options.selectManyTable)){
        //take into account the repeat - have to decompose the sub elements in json
        //      use json_array_elements() - Expands a JSON array to a set of JSON elements
        //reapeat from definition:
        // TODO: clean up code due to incorporation of select many...

        // ****  the from of the repeat
        let repeatFrom=`(SELECT (id || '_' || data2.idx)${textFieldCast} as id,`
        let parentName='parent_id'
        
        // has to change the logic, we have to get element in the correct order unless id don't work and/or are not always the same. Many times the repeat order can be important
        // https://stackoverflow.com/a/48944228/140384
        repeatFrom+='data2.data as doc, data2.idx as idx, '
        if(this.isSubFrom(from_definition)){
          parentName=this.getSubFromName(from_definition)+'_id'
          repeatFrom+='doc_id, '
        }else{
          repeatFrom+='id as doc_id, '
        }
        repeatFrom+=`id${textFieldCast} as parent_id `
        repeatFrom+='FROM '+ from_definition 
        if(x.hasOwnProperty('children')){
          repeatFrom+=', jsonb_array_elements('
        }else{
          repeatFrom+=', jsonb_array_elements_text_2('
        }
        repeatFrom+= "doc#>'{"+this.namePath(namePath,x.name)+"}'" +') with ordinality AS data2(data,idx)'

        if(this.isSubFrom(from_definition)){
          repeatFrom+=" WHERE "          
        }else{
          repeatFrom+=" WHERE id LIKE 'survey_%' and "
          if (!options.includeDeletedSurveys){
            repeatFrom+="COALESCE((doc->'survey_deleted')::bool, false)=false and "
          }
        }
        //select many - because do not print null, and not handle by jsonb_array_elements_text
        //normal case - jsonb_array_elements crash on null 
        repeatFrom+=" doc#>>'{"+this.namePath(namePath,x.name)+"}' is not null"
        repeatFrom+=' ) as '+x.name

        // ****  the repeat itself
        const curReapeatName = `${options.schema_val}.${options.v_name}_${x.name}`
        let curReapeatSql=[]
        curReapeatSql.push(`DROP ${options.metrializedView?'MATERIALIZED':''} VIEW IF EXISTS ${curReapeatName} CASCADE;`)
        curReapeatSql.push(`/* SELECT * from ${options.v_name}_${x.name}; */`)//for testing.
        curReapeatSql.push(`CREATE ${options.metrializedView?'MATERIALIZED':''} VIEW ${curReapeatName} as`)
        curReapeatSql.push('SELECT ')
        curReapeatSql.push(this.getColumn("id", x.name + "_id",options))
        if(options.repeatNumber){
          curReapeatSql.push(this.getColumn("idx" , "no",options))
        }
        if(this.isSubFrom(from_definition)){
          curReapeatSql.push(this.getColumn("parent_id" , parentName,options ))
          curReapeatSql.push(this.getColumn("doc_id", "doc_id",options))
        }else{
          curReapeatSql.push(this.getColumn("parent_id" , 'doc_id',options ))
        }
        if(options.attachements){ // if we have attachments, we need the nb field.
          curReapeatSql.push("idx as nb,") //probably don't work sub sub view... anyway, not a good design!!
        }
        
        if(x.hasOwnProperty('children')){
          //child repeat
          const newNamePath=namePath.slice()
          newNamePath.push(x.name)
          this.createViewSql_sub(x.children,formDefinition,curReapeatSql,repeatSql,[],form_id,options,repeatFrom,[], otherSQL)
          curReapeatSql.pop()// remove last comma
        }else{
          // it's a select many
          curReapeatSql.push(this.getColumn('doc', x.name, options,false))
        }
        curReapeatSql.push(' FROM '+ repeatFrom)
        curReapeatSql.push(';')
        options['grantUsers_val']?.split(',')?.forEach(x=>{
          curReapeatSql.push(`grant select on ${curReapeatName} to ${x};`)
        })
        repeatSql.push(curReapeatSql)
      }else if(x.hasOwnProperty('children')){
        //we don't prefix the name for the moment, we asses that it's unique in all groups.
        const newNamePath=namePath.slice()
        newNamePath.push(x.name)
        this.createViewSql_sub(x.children,formDefinition,sql,repeatSql,newNamePath,form_id,options,from_definition,doneFieldsName, otherSQL)
      }
      if(x.hasOwnProperty('children')===false && x.hasOwnProperty('name')){
        // take into accout diffrent type of field.
        // #> return a jsonb field.
        // #>> return an ok text
        // (xx#>xx)::text - return a filed with ""
        // look for number solution...

        // *** choices if yes populate
        let opts = null
        if(x.hasOwnProperty('choices')){
          opts= x.choices
        }else if(x.hasOwnProperty('itemset')){
          opts= formDefinition.choices[x.itemset]
        }
        if(opts && (options.exportChoices || options.showLabelValue)){
          opts.forEach(opt => {
            otherSQL.choices.push(`('${x.name}','${opt.name}','${ replaceAll(opt.label?opt.label:opt.name,"'","''") }')`)
            otherSQL.choices.push(`,`)
          });
        }

        // **** Field type
        if(x.type=='text'){
          sql.push(`(doc#>>'{${this.namePath(namePath,x.name)}}')${x.appearance!='multiline'?textFieldCast:''} AS ${quoteSqlIdentifier(x.name, doneFieldsName)}`)
        }else if (x.type=='select one') {
          if(options.showLabelValue){
            sql.push(`(${options.schema_val}.getChoices_${form_id}('${x.name}',doc#>>'{${this.namePath(namePath,x.name)}}'))${textFieldCast} AS ${quoteSqlIdentifier(x.name, doneFieldsName)}`)
          }else{
            sql.push(`(doc#>>'{${this.namePath(namePath,x.name)}}')${textFieldCast} AS ${quoteSqlIdentifier(x.name, doneFieldsName)}`)
          }
        }else if (x.type=='select all that apply') {
          //value:
          if(!options.selectManyNoStringConcatenation){
            let getVal = '('
            if(options.showLabelValue){
              getVal = `${options.schema_val}.getChoices_${form_id}('${x.name}',`
            }
            let val=`(
              CASE WHEN (doc#>>'{${this.namePath(namePath,x.name)}}') is null THEN 
                null 
              ELSE 
                array_to_string(
                  array(
                    select ${getVal}jsonb_array_elements_text_2(doc#>'{${this.namePath(namePath,x.name)}}'))
                  )
                  ,','
                )
              END)`
            sql.push(`(${val})${textFieldCast} AS ${quoteSqlIdentifier(x.name, doneFieldsName)}`)
          }
          // create a separate table for the select many choices - see repeat
        }else if (['geopoint','geoshape','geotrace'].indexOf(x.type)>-1) {

          if(options.postgis){
            if (x.parameters && x.parameters.indexOf('format=wkt') != -1){
              sql.push("ST_geomFromText_2(doc#>>'{"+this.namePath(namePath,x.name)+"}') AS geom_"+ x.name )
            }else if(x.parameters && x.parameters.indexOf('format=survey_cto') != -1){
              if(options.numbersErrors){
                sql.push("ST_Point( convert_to_float( doc#>>'{"+this.namePath(namePath,x.name + '-Longitude')+"}'), convert_to_float( doc#>>'{"+this.namePath(namePath,x.name +'-Latitude')+"}') ) AS geom_"+ x.name )
              }else{
                sql.push("ST_Point(doc#>>'{"+this.namePath(namePath,x.name + '-Longitude')+"}'::float, doc#>>'{"+this.namePath(namePath,x.name +'-Latitude')+"}'::float) AS geom_"+ x.name )
              }
            }else{
              sql.push("ST_GeomFromGeoJSON(doc#>>'{"+this.namePath(namePath,x.name)+",geometry}') AS geom_"+ x.name )
            }
            sql.push(',')
          }
          if(x.type=='geopoint'){
            if(options.gpsaccuray){
              sql.push("NULLIF((doc#>>'{"+this.namePath(namePath,x.name)+",properties,accuracy}'),'')::float AS "+ x.name +'_accuracy')
              sql.push(',')
            }
            //add also the latitude and longitude (x,y) for points
            if(x.parameters && x.parameters.indexOf('format=survey_cto') != -1){
              sql.push("NULLIF((doc#>>'{"+this.namePath(namePath,x.name + '-Longitude')+"}'),'')::float AS "+ x.name +'_x')
              sql.push(',')
              sql.push("NULLIF((doc#>>'{"+this.namePath(namePath,x.name + '-Latitude') + "}'),'')::float AS "+ x.name +'_y')              
              sql.push(',')
              sql.push("NULLIF((doc#>>'{"+this.namePath(namePath,x.name + '-Altitude') + "}'),'')::float AS "+ x.name +'_z')              
              sql.push(',')
              sql.push("NULLIF((doc#>>'{"+this.namePath(namePath,x.name + '-Accuracy') + "}'),'')::float AS "+ x.name +'_accuracy')              
            } else if(x.parameters && x.parameters.indexOf('format=wkt') != -1){
              sql.push("(doc#>>'{"+this.namePath(namePath,x.name)+"}') AS "+ x.name)
              sql.push(',')
              sql.push(" ST_X_2 (ST_geomFromText_2(doc#>>'{"+this.namePath(namePath,x.name)+"}')) AS "+ x.name +'_x')
              sql.push(',')
              sql.push("ST_Y_2 (ST_geomFromText_2(doc#>>'{"+this.namePath(namePath,x.name)+"}')) AS "+ x.name +'_y')
            }else{
              sql.push("(doc#>>'{"+this.namePath(namePath,x.name)+",properties,input_type}') AS "+ x.name +'_type')
              sql.push(',')
              sql.push("NULLIF((doc#>>'{"+this.namePath(namePath,x.name)+",geometry,coordinates,0}'),'')::float AS "+ x.name +'_x')
              sql.push(',')
              sql.push("NULLIF((doc#>>'{"+this.namePath(namePath,x.name)+",geometry,coordinates,1}'),'')::float AS "+ x.name +'_y')
              sql.push(',')
            }
          }
          if(x.parameters && !(x.parameters.indexOf('format=survey_cto') != -1 || x.parameters.indexOf('format=wkt') != -1)){
            sql.push(`(doc#>>'{${this.namePath(namePath,x.name)},geometry}')${textFieldCast} AS ${quoteSqlIdentifier(x.name, doneFieldsName )}`)
            sql.push(',')
          }
        }else if (['photo','image','file','video','audio'].indexOf(x.type)>-1) {
          //first get the id
          sql.push(this.getColumn(`doc#>>'{${this.namePath(namePath,x.name)}}'`,  x.name +'_id',options ))
          //second the url
          //these are attached documents.
          //the attachemnt is store in another document which this value point to. (_id)
          // we want the url of the attachment
          // for photo:
          //https://dbforms.aecom.space/form_cji1zlmyr000kiate16y7fz2p/photo_cji4mkz5w00nement0mgw1nrl/photo
          let url1=options.couchDbUrl+form_id+'/'
          //the type could be defined by the id of the document, so photo_dsfffds is photo as attachment. More generic.
          if(x.type=='photo'){
            // We create a function for the photo url, as we want to override it sometimes (s3 signed url or other)
            // no option, as we won't convert to varchar 255, can be longer
            sql.push(this.getColumn(`${options.schema_val}.photo_${form_id}(` + "doc#>>'{"+this.namePath(namePath,x.name)+"}')" ,  x.name,{} ,false))
          }else{
            // Other, not implemented, but we should name them "attachment", more generic
            // TODO: or even better, get by the attachments names...
            // sql.push("('" + url1 + "' || (doc#>>'{"+this.namePath(namePath,x.name)+"}') || '/attachment') AS "+ x.name)
            sql.push(this.getColumn("'" + url1 + "' || (doc#>>'{"+this.namePath(namePath,x.name)+"}') || '/attachment'" ,  x.name,options,false ))
          }
        }else if (x.type=='decimal') {
          if(options.numbersErrors){
            sql.push("convert_to_float(doc#>>'{"+ this.namePath(namePath,x.name)+"}') AS "+ quoteSqlIdentifier(x.name, doneFieldsName ))
          }else{
            sql.push("NULLIF((doc#>>'{"+ this.namePath(namePath,x.name)+"}'),'')::float AS "+ quoteSqlIdentifier(x.name, doneFieldsName ))
          }
        }else if (x.type=='integer') {
          if(options.numbersErrors){
            sql.push("convert_to_int(doc#>>'{"+ this.namePath(namePath,x.name)+"}') AS "+ quoteSqlIdentifier(x.name, doneFieldsName )) // convert first o integer, because sometimes user may have inpu decimals...
          }else{
            sql.push("NULLIF((doc#>>'{"+ this.namePath(namePath,x.name)+"}'),'')::float::integer AS "+ quoteSqlIdentifier(x.name, doneFieldsName )) // convert first o integer, because sometimes user may have inpu decimals...
          }
        }else{
          sql.push(`(doc#>>'{${this.namePath(namePath,x.name)}}')${textFieldCast} AS ${quoteSqlIdentifier(x.name, doneFieldsName )}`)
        }
        //only add if we have a new value - some options might not return nothing, as select many
        if(sql[sql.length-1]!=','){
          sql.push(',')
        }
      }
    })
  },
  isSubFrom(from_definition){
    if(from_definition.indexOf('FROM')>-1){
      return true
    }
    return false
  },
  getSubFromName(from_definition) {
    // https://stackoverflow.com/a/20883434/140384
    return from_definition.trim().split(' ').splice(-1);
  },
  getDocColumn(options){
    if(options.varchar){
      return "id::varchar(255) AS doc_id"
    }
    return "id AS doc_id"
  },
  getColumn(val, name, options,tailComma=true){
    let tail0=''
    if(tailComma){
      tail0=','
    }
    return `(${val})${options.varchar?'::varchar(255)':''} AS ${name}${tail0}`
  },
  // generateColumn(path,type,asName){
  //   if(type){
  //     return
  //   }
  // },
  namePath(namePath,name){
    //build the concatenation, useful as when namePath is length 0..
    const newNamePath=namePath.slice()
    newNamePath.push( quoteSqlIdentifier(name) )
    return newNamePath.join(',')
  },
  //**********************************************************************
  //       Generate attachment list - useful for program that fetch the attachemnt locally
  //         programmed in python.
  //**********************************************************************
  generateAttachmentList(form_id,sql_attachment_list,options){
    //TODO use: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Template_literals
    const baseSql=`
    DROP ${options.metrializedView?'MATERIALIZED':''} VIEW IF EXISTS ${options.schema_val}.@v_name@_@suffix@_list CASCADE;
    CREATE ${options.metrializedView?'MATERIALIZED':''} VIEW ${options.schema_val}.@v_name@_@suffix@_list as
    SELECT @fields@
      FROM @v_name@
      INNER JOIN @v_name@_@suffix@
        ON @v_name@.doc_id = @v_name@_@suffix@.doc_id
      WHERE (((@v_name@_@suffix@.@not_null@) IS NOT NULL));
    `
    let sql=sql_attachment_list.map(x=>{
      let newSql=utils_gen.replaceAll(baseSql,'@form_id@',form_id) // no more usefull... could be...
      newSql=utils_gen.replaceAll(newSql,'@v_name@',options.v_name)
      newSql=utils_gen.replaceAll(newSql,'@suffix@',x.table_suffix)
      newSql=utils_gen.replaceAll(newSql,'@not_null@',x.not_null)
      let a1=x.fields.map(x2=>{
        return x2.definition + ' AS ' + x2.name
      })
      newSql=utils_gen.replaceAll(newSql,'@fields@',a1.join(','))
      return newSql
    })
    return sql.join(';')
  },
  unionAttachmentList(sql,options){
    let list_names=sql.match(/VIEW\s\w+_list/gm)
    //https://stackoverflow.com/a/14438954/140384
    function onlyUnique(value, index, self) {
        return self.indexOf(value) === index
    }
    var unique = list_names.filter( onlyUnique )
    let sql2=unique.map(x=>{
      return 'SELECT * FROM '+ utils_gen.replaceAll(x,'VIEW ','')
    })
    //TODO use: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Template_literals
    let baseSql=`DROP VIEW IF EXISTS ${options.schema_val}.v_attachments_list_@date@ CASCADE;
    CREATE VIEW ${options.schema_val}.v_attachments_list_@date@ as `
    const d1=new Date()
    baseSql=utils_gen.replaceAll(baseSql,'@date@',moment().format('YYYYMMDD'))
    return baseSql + sql2.join(' UNION ') + ';'
  },
  createViewSqlAttachments(form_id,formDefinition,options){
    // create a view with all the attachments in one view
    // 3 field type(name of the survey question), id (as in the data), url
    let sql=[]

    sql.push(`DROP ${options.metrializedView?'MATERIALIZED':''} VIEW IF EXISTS ${options.schema_val}.${options.v_name}_attachements CASCADE;`)
    sql.push(`/* SELECT * from ${options.schema_val}.${options.v_name}_attachements; */`)//for testing.
    sql.push(`CREATE ${options.metrializedView?'MATERIALIZED':''} VIEW ${options.schema_val}.${options.v_name}_attachements as `)
    this.createViewSqlAttachments_sub(formDefinition.children,formDefinition,sql,[options.v_name,'doc_id'],form_id,options,form_id)
    sql.pop()// remove last union all
    sql.push(';')
    return sql.join(' ')
  },
  createViewSqlAttachments_sub(children,formDefinition,sql,namePath,form_id,options,from_definition){
    //create the fields for the childs.
    //from_definition holds a string that is used in the from clauses below. allow for sub repeat
    children.map(x=>{
      //should always have a name if we have children.
      if(x.type=='repeat' ){
        //child repeat
        this.createViewSqlAttachments_sub(x.children,formDefinition,sql,[options.v_name + '_'+ x.name,x.name + "_id" ],form_id,options,from_definition)
      }else if(x.hasOwnProperty('children')){
        this.createViewSqlAttachments_sub(x.children,formDefinition,sql,namePath,form_id,options,from_definition)
      }
      if(x.hasOwnProperty('children')===false && x.hasOwnProperty('name')){
        if (['photo','image','file','video','audio'].indexOf(x.type)>-1) {
          sql.push('SELECT \''+x.name + '\' as type,'+ namePath[1] + ',' + x.name + ' FROM ' + namePath[0])
          sql.push('UNION ALL')
        }
      }
    })
  },
}

const quoteSqlIdentifier = (iden, doneFieldsName) =>{
  let iden2 =  iden
  if (doneFieldsName){
    while (doneFieldsName && doneFieldsName.indexOf(iden2) != -1){
      iden2 += '_2'
      // console.log(iden2)
    }
    doneFieldsName.push(iden2)
    // console.log(doneFieldsName)
  }
  return '"' + iden2 + '"'
}
