import base from './_baseInput.js'
import formUtils from '../../utils/form_utils.js'
import utils_gen from '../../utils/utils_gen.js'
import cuid from 'cuid'

export default{
  mixins:[base],
  props:['editingIndexGiven'],
  data(){
    return {
      isAdding:false,
      opened:true,
      editingIndex:-1,
      newEditingIndex: null,
      editingElement:null,
      newElementId:cuid(), 
      hasToScrool:false,
    }
  },
  beforeCreate: function () {
    //if we use normal registration, it doesn't work.
    //https://vuejs.org/v2/guide/components-edge-cases.html#Circular-References-Between-Components
    this.$options.components.IGroup = require( '../inputGroup.vue').default
  },
  computed:{
    fields(){
      let fis=[]
      this.colRepeatVisible(this.formElements, fis, [], true)
      fis.unshift({key:'editButton',label:this.labels.inputs.edit})
      return fis
    },
    editingStatus(){
      if(this.editingIndex!=-1){
        return this.status[this.editingIndex]
      }
      return null
    },
    
    valueForTable(){
      if(this.editingIndex!=-1){
        // do this to remove the vue.js reactivty that cause the a slice of array to retain the prop...
        const obj=JSON.parse(JSON.stringify(this.value))
        obj[this.editingIndex]._rowVariant= 'success'
        return obj
      }
      return this.value
    }
  },
  watch:{
    editingIndex(){
      if(this.editingIndex!=-1){
        this.editingElement=this.myValue[this.editingIndex]
        this.hasToScrool=true
      }else{
        this.editingElement=null
      }
    },
    value(){
      // when the value change, we are in a different part of the form, not just an edition. So just close any open repeat element.
      if(this.newEditingIndex !== null){
        this.editingIndex = this.newEditingIndex
        this.newEditingIndex = null
      }else{
        this.editingIndex = -1
      }
    },
    currentPage:{
      handler(){
        this.editingIndex=-1
        if(this.hasPages){
          let index=this.currentPage.pathComplete.indexOf(this.config.name)
          if (index>-1 && this.currentPage.pathComplete.length>(index+1)){
            this.editingIndex=this.currentPage.pathComplete[index+1]
          }
        }
      },
      immediate:true
    },
    editingIndexGiven:{
      immediate:true,
      handler(){
        if(this.editingIndexGiven!==null){
          this.editingIndex=this.editingIndexGiven
        }
      }
    }
  },
  methods:{
    updateValue(value){
      this.myValue.splice(this.editingIndex,1,value)
    },
    changeOrder(currentIndex,newIndex){
      this.editingIndex = -1
      this.myValue.splice(newIndex,0,this.myValue.splice(currentIndex,1)[0])
    },
    addNew(){
      //add a new in the myValue array. has to have to props necessary. empty
      // console.log('repeat - new element')
      // console.log(this.config)
      let newEl=formUtils.buildEmptySurveyData({},this.config, this.surveyDoc, this.namePath.concat([this.config.name]))
      // console.log(newEl);
      
      //setting new index value make it visible for edition.
      this.newEditingIndex=this.myValue.push(newEl.form_data)-1
    },
    deleteCurrent(){
      //delete the current repat element.
      this.myValue.splice(this.editingIndex,1)
      this.editingIndex=-1
    },
    newNamePath(index){
      return this.namePath.concat([this.config.name,index])
    },
    getVariantStatus(index){
      const tree=utils_gen.getPropTree2(this.surveyDoc.form_status,this.namePath.concat([this.config.name,index]))
      if (formUtils.getStatusOfTree(tree)>10){
        return 'warning'
      }
      return null
    },
    clickIndex(index){
      if(this.editingIndex==index){
        // remove
        this.editingIndex=-1
      }else{
        this.editingIndex=index
      }
    },
    formatterForArray(val){
      if(val && Array.isArray(val)){
        return val.join(', ')
      }
      return null
    },
    colRepeatVisible(elements, fis, parents, alsoEmptyVal){
      elements.map(x=>{
        // in group we only want true in the forms, but direct child, want all not false.
        // change the only real true for that.
        const visibleTrue = (x.col_repeat_visible || 'false').toLowerCase()=='true'
        const col_repeat_visible= x.col_repeat_visible?visibleTrue:alsoEmptyVal
        if(['repeat','note'].indexOf(x.type)==-1 && col_repeat_visible){
          const newParents=parents.concat(x.name)
          if(x.type=='select all that apply'){
            //array
            fis.push( {key: newParents.join('.'),label:x.label,formatter:this.formatterForArray})
          }else if(x.type == 'group'){
            // get the sub fields
            if(visibleTrue){ // only group with the prop as true has to be visible.
              this.colRepeatVisible(x.children, fis, newParents, false)
            }
          }else{
            //normal
            fis.push( {key: newParents.join('.'),label:x.label})
          }
        }
      })
    }
  }

}