<template>
  <div class="container-fluid">
    <div class="row justify-content-md-center">
      <div class="col-lg-6">
        <div class="h2 text-dark">
          {{ labels.title }}
        </div>
        <h5 class="ml-5 my-3">{{ labels.appVersion }}: {{$store.state.settings.version}}</h5>
        <b-card no-body>
          <b-tabs content-class="mt-3" card>
            <b-tab :title="labels.tab_database">
              <h3 class="">{{ labels.selectDbServer }}: </h3>
              <div class="list-group ml-4">
                <div class="list-group-item" :class="{active:db==currentDb}" v-for="(db,i) in settings.couchdbUrls" :key="i">
                  {{db}}
                  <b-btn @click="activate(db)" variant="dark" v-if="db!=currentDb" class="float-right">Make current</b-btn>
                  <b-btn disabled v-else variant="dark" class="float-right">Is current</b-btn>
                  <!--   -->
                  <b-btn v-if="$store.getters.isAdmin || db.indexOf('192')!=-1" class="float-right mr-2" size="sm" :href="db+'_utils'" target="_blank"><i class="mdi mdi-sofa"></i></b-btn>
                  <b-btn v-if="$store.getters.isAdmin || db.indexOf('192')!=-1" class="float-right mr-2" size="sm" :href="db.replace('https://db.','https://adminer.')" target="_blank"><i class="mdi mdi-database"></i></b-btn>
                </div>
              </div>
  
              <h3 class="mt-5">{{ labels.ipCertificate }}</h3>
              <div>
                <div class="ml-4">
                  <b-btn variant="primary" class="mt-2" href="/static/cert/myCA_der.crt">Android</b-btn> 
                  <a class="ml-3" href="https://www.lastbreach.com/blog/importing-private-ca-certificates-in-android">help install</a>
                </div>
                <div class="ml-4">
                  <b-btn variant="primary" class="mt-2" href="/static/cert/myCA.pem">Windows / Desktop</b-btn>
                  <a class="ml-3" href="https://support.securly.com/hc/en-us/articles/206081828-How-to-manually-install-the-Securly-SSL-certificate-in-Chrome">help install</a>
                </div>
              </div>
            </b-tab>
            <b-tab :title="labels.tab_functionalities">
              <div class="h3">{{ labels.allowRepeatReorders }}</div>
              <div class="ml-4">
                <b-btn variant="primary" v-if="$store.state.allowRepeatReorder==false" class="mt-2" @click="$store.commit('setAllowRepeatReorder', true)">{{ labels.allowRepeatReordersButton }}</b-btn>
                <b-btn variant="secondary" v-else class="mt-2" @click="$store.commit('setAllowRepeatReorder', false)">{{ labels.allowRepeatReordersButton2 }}</b-btn>
              </div>
              <div class="h3 mt-5">{{ labels.localDataDeletion }}</div>
              <div class="ml-4">
                <b-btn variant="warning" v-if="$store.state.allowLocalDataDeletion==false" class="mt-2" @click="$store.commit('setAllowLocalDataDeletion', true)">{{ labels.localDataDeletionButton }}</b-btn>
                <b-btn variant="warning" v-else class="mt-2" @click="$store.commit('setAllowLocalDataDeletion', false)">{{ labels.localDataDeletionButton2 }}</b-btn>
              </div>
              <div class="h3 mt-5">{{labels.copypaste}}</div>
              <div class="ml-4">
                <div>
                  {{ labels.copypasteMessage }}
                </div>
                <b-btn variant="warning" v-if="$store.state.allowCopyPaste==false" class="mt-2" @click="$store.commit('setAllowCopyPaste', true)">{{ labels.copypasteButton }}</b-btn>
                <b-btn v-else class="mt-2" @click="$store.commit('setAllowCopyPaste', false)">{{ labels.copypasteButton2 }}</b-btn>
              </div>
              <div class="h3 mt-5">{{ labels.saveAppMessages }}</div>
              <div class="ml-4">
                <b-btn variant="secondary" v-if="$store.state.save_app_messages==false" class="mt-2" @click="$store.commit('set_save_app_messages', true)">{{ labels.saveAppMessagesButton }}</b-btn>
                <b-btn v-else class="mt-2" @click="$store.commit('set_save_app_messages', false)">{{ labels.saveAppMessagesButton2 }}</b-btn>
                <div>
                  <b-btn class="mt-4" variant="primary" @click="$store.dispatch('app_messsages_sync')">{{ labels.saveAppMessagesButton3 }}</b-btn>
                </div>
              </div>
            </b-tab>
            <b-tab :title="labels.tab_tools">
              <!-- <div class="alert alert-info" v-if="$store.getters.isAdmin"> -->
              <div class="alert alert-info">
                <div v-if="$store.getters['sqlApi/isAuthenticated']">SqlApi - ok</div>
                <div v-else>SqlApi - not authenticated</div>
              </div>
              <div class="h3 mt-4">Debug tools</div>
              <div class="row mt-3">
                <div class="col-4">
                  <div class="d-flex flex-column g-2">
                    <b-btn class="mt-2" @click="getToken">Get Token</b-btn>
                    <b-btn class="mt-2" @click="getSession">Get CouchDB session</b-btn>
                    <b-btn class="mt-2" @click="getQuota">Get Quota</b-btn>
                    <b-btn class="mt-2" @click="getSys">Get Sys local</b-btn>
                    <b-btn class="mt-2" @click="getPersist">Get Persist Storage Status</b-btn>
                    <!-- <b-btn class="mt-2" @click="t1">test #1</b-btn>
                    <b-btn class="mt-2" @click="t2" v-if="$store.getters.isAdmin">test #2</b-btn> -->
                    <!-- <b-btn class="mt-2" @click="t3">test conflicts</b-btn> -->
                    <b-btn class="mt-2" to="/error">view error page</b-btn>
                  </div>
                </div>
                <div class="col-8">
                  <div v-if="err" class="alert alert-warning">
                    {{err}}
                  </div>
                  <div v-if="doc" class="alert alert-secondary">
                    {{doc}}
                  </div>
                  <div v-if="jsonTable">
                    <b-table striped small :items="jsonTable"></b-table>
                  </div>
                </div>
              </div>
              <div class="h3 mt-5">Storage problems...</div>
              <div class="ml-4">
                <b-btn variant="primary" class="mt-2" href="/static/fennec-65.0.en-AECOM-arm.apk">Download fennec (to solve quota problems)</b-btn>
              </div>
              <div class="mt-2 ml-4">
                <a class="ml-3" href="https://firefox-storage-test.glitch.me/" target="_blank">https://firefox-storage-test.glitch.me/</a>
              </div>
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
    </div>    
  </div>
</template>

<script>
import { Promise } from 'q';
import cuid from 'cuid'
import getQuota from '../../utils/quota'
import axios from 'axios';
var filesize = require('file-size');

export default {
  name:'help',
  data(){
    return{
      doc:null,
      err:null,
      jsonTable:null
    }
  },
  computed:{
    labels(){return this.$store.state.labels.helpPage},
    settings(){
      return this.$store.state.settings
    },
    currentDb(){
      return this.$store.state.couchdbUrl
    }
  },
  methods:{
    size(val){
      //get a human readable size.
      return filesize(val,{fixed:1}).human('si')
    },
    activate(db){
      //change the connection DB:
      this.$store.dispatch('setCouchDBUrl',{force:true,url:db})
      this.$router.push('/')
    },
    //  **** TOOLS
    clearTools(){
      this.err=null
      this.doc=null
      this.jsonTable=null
    },
    getToken(){
      this.clearTools()
      // https://stackoverflow.com/a/38552302
      function parseJwt (token) {
          var base64Url = token.split('.')[1];
          var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
          var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
              return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          }).join(''));

          return JSON.parse(jsonPayload);
      }
      this.doc=parseJwt(localStorage.getItem('user-token-couchdb'))
    },
    getSession(){
      this.clearTools()
      axios.get(this.$store.getters.sessionUrl, {headers: {'Authorization': "Bearer " + localStorage.getItem('user-token-couchdb')}}).then(rep=>{
        const rep2=rep.data
        rep2.url = this.$store.getters.sessionUrl
        this.doc=rep2
      }).catch(err=>{
        this.err=err
      })
    },
    getSys(){
      this.clearTools()
      try {
        let bd1=this.$PouchDB('aecom_forms_local_system', this.$store.getters.pouchDbOptions)
        bd1.get('config').then(doc=>{
          this.doc=doc
        }).catch(err=>{
          this.$store.dispatch('app_message_error',err)
          this.err=err
        })
      } catch (e) {
        this.$store.dispatch('app_message_error',err)
        this.err=e
      }
    },
    getQuota(){
      this.clearTools()
      getQuota.getQuota().then(estimate=> {
        this.doc={usage: this.size(estimate.usage), quota:this.size(estimate.quota), Percent: (estimate.usage/estimate.quota*100).toFixed(1) + ' %'}
      }).catch(err=>{
        this.$store.dispatch('app_message_error',err)
        this.err=err
      })
    },
    getPersist(){
      this.clearTools()
      navigator.storage.persist().then(granted => {
        this.doc = {persisted_storage: granted}
      }).catch(err => {
        this.$store.dispatch('app_message_error',err)
        this.doc=err
      });
    },
    t1(){
      this.clearTools()
      indexedDB.databases().then(r => {
        this.doc=r
      })
      // get the ids
      this.jsonTable=null
      let db1=new this.$PouchDB('null' + this.$store.state.form.form_id, this.$store.getters.pouchDbOptions)
      db1.put({_id:'test_'+cuid(),device:this.$store.getters['localDB/device_id']}).then(rep=>{
        return db1.allDocs()
      }).then(rep2=>{
        this.jsonTable=rep2.rows
      })
      // this.doc=webkitGetDatabaseNames()
      // indexedDB.databases().then(r => {
      //   this.doc=r
      //   const rep=[]
      //   // if we have a null database:
      //   Promise.all(
      //   r.map(x=>{
      //     if(x.name.indexOf('null')==7){
      //       let db0=new this.$PouchDB(x.name.substring(7))
      //       return db0.allDocs().then(rep2=>{
      //         return rep2.rows
      //       })
      //     }
      //     return []
      //   })).then(reps=>{
      //     reps.map(rep0=>{
      //       rep.concat(rep0)
      //     })
      //     if (rep.length>0){
      //       this.jsonTable=rep
      //     }
      //   })
      // })
    },
    t2(){
      this.clearTools()
      // copy a survey from normal form to null...
      let db1=new this.$PouchDB('null'+this.$store.state.form.form_id, this.$store.getters.pouchDbOptions)
      let db2=new this.$PouchDB(this.$store.state.form.form_id, this.$store.getters.pouchDbOptions)
      db2.allDocs({startkey:'survey_',endkey:'survey_\ufff0',limit:1,include_docs:true}).then(rep=>{
        let doc=rep.rows[0].doc
        delete doc._rev
        db1.put(doc)
      })
    },
    t3(){
      this.clearTools()
      this.$store.state.form.db.get('_design/editList', {conflicts: true}).then(doc=> {
        // do something with the doc
        this.doc=doc
      }).catch(err=> {
        this.$store.dispatch('app_message_error',err)
        // handle any errors
        this.doc=err
      });
    },
  },
}
</script>

<style>

</style>
