import Vue from 'vue'
import Vuex from 'vuex'
import PouchDB from 'pouchdb'
import labels_fr from './labels_fr.js'
import labels_en from './labels_en.js'
import localDbStore from './localDbStore.js'
import localConfig from './localConfig.js'
import form from './form.js'
import formMap from './formMap.js'
import modals from './modals.js'
import position from './position.js'
import settings from './settings/settings.js'
import project from './project.js'
import map from './map/map.js'
import messages from './messages.js'
import axios from 'axios';
import utils_gen from '../utils/utils_gen.js'
import sqlApi from './sqlApi'
import moment from 'moment'

Vue.use(Vuex)

global.lastEditSurveyDoc=null //props we want to manage easily accessible... could be in the state.

export default new Vuex.Store({
  modules:{
    localDB: localDbStore,
    localConfig: localConfig,
    form: form,
    formMap: formMap,
    modals: modals,
    position: position,
    settings: settings,
    project: project,
    map: map,
    sqlApi: sqlApi,
    messages: messages,
  },
  state:{
    labels:labels_fr,
    // language code of the app, reference: https://iso639-3.sil.org/code_tables/639/data to get the code. Enve if the 3 version should be fra... but we use fr... to be seen if useful...
    language: localConfig.state.language ?? 'fr',
    //system DB
    dbDatabaseSystem:null,
    systemDoc:null,
    //user loggedin
    isLogged:null, // null at the begining so the router know when to fire verifySession
    userName:null,
    session:null, // hold the json response of the _session couchdb 
    sessionTimer:null,
    error_message:null,
    app_messages:[],
    save_app_messages: false,
    couchdbUrl:null,
    cuidIsDeleting:null, // support for the delete button to be multiple times on the same page and still get that another one has been clicked
    // cToken: null,
    allowLocalDataDeletion: false,
    allowCopyPaste: false,
    allowRepeatReorder: false,
  },
  getters: {
    databaseSystem(state){
      return state.couchdbUrl+state.settings.databaseSystem
    },
    sessionUrl(state){
      return state.couchdbUrl+'_session'
    },
    roles(state, getters, rootState, rootGetters){
      if(state.session){
        return state.session.userCtx.roles
      }
      return rootState.localDB.config?.userCtx?.roles ?? []
    },
    isAdmin(state){
      if(state.session && state.session.userCtx.roles.indexOf('_admin')>-1){
        return true
      }
      return false
    },
    projects(state){
      if(state.systemDoc){
        return state.systemDoc.projects
      }
      
    },
    forms(state){
      const forms=[]
      if(state.systemDoc){
        state.systemDoc.projects.map(pro=>{
          pro.forms.map(form=>{
            forms.push(form)
          })
        })
      }
      return forms
    },
    userName(state, getters, rootState, rootGetters){
      return state.userName ?? rootState.localDB?.config?.userName
    },
    utcCurrentTime(){
      return ()=>moment().utc().format()
    },
    pouchDbOptions(){
      return {
        fetch: function (url, opts) {
          opts.headers.set('Authorization', 'Bearer ' + localStorage.getItem('user-token-couchdb'));
          return PouchDB.fetch(url, opts);
        }
      }
    },
  },
  mutations:{
    login(state,opt){
      if(opt.userName){
        state.isLogged=true
        state.userName=opt.userName
        state.dbDatabaseSystem=opt.db
      }else{
        state.isLogged=false
        state.userName=null
        state.dbDatabaseSystem=null
      }
    },
    isLoggedToNull(state){ //useful to reset the islogged state so it's check again by the router gard.
      state.isLogged=null
    },
    systemDoc(state,doc){
      state.systemDoc=doc
    },
    error_message(state,error_message){
      state.error_message=error_message
    },
    cuidIsDeleting(state,cuidIsDeleting){
      if(cuidIsDeleting){
        state.cuidIsDeleting=cuidIsDeleting
      }else{
        state.cuidIsDeleting=null
      }
    },
    setAllowLocalDataDeletion(state, newVal){
      state.allowLocalDataDeletion = newVal
    },
    setAllowRepeatReorder(state, newVal){
      state.allowRepeatReorder = newVal
    },
    setAllowCopyPaste(state, newVal){
      state.a,llowCopyPaste = newVal
    },
    set_save_app_messages(state, newVal){
      state.save_app_messages = newVal
    },
  },
  actions:{
    switchLanguage(context,newLanguage){
      context.state.language=newLanguage
      context.dispatch('localConfig/setConfig',{key: 'language', val: context.state.language})
      if (newLanguage=='fr'){
        context.state.labels=labels_fr
      }else{
        context.state.labels=labels_en
      }
    },
    setCouchDBUrl(context,opts={force:false}){
      //get the couchdbUrl. because we have in settings possibly many couchdb urls, we have to get the one we whish to use.
      //only checked the first time, next time used the first resolved one.
      if(context.state.couchdbUrl && opts.force===false){ //force=true, force a refresh.
        return Promise.resolve()
      }else if(Array.isArray(context.rootState.settings.couchdbUrls)==false){
        context.state.couchdbUrl=context.rootState.settings.couchdbUrls
        axios.defaults.baseURL=context.state.couchdbUrl
        return Promise.resolve()
      }else if(context.rootState.settings.couchdbUrls.length==1){
        context.state.couchdbUrl=context.rootState.settings.couchdbUrls[0]
        axios.defaults.baseURL=context.state.couchdbUrl
        return Promise.resolve()
      }
      let arrAxios=[]
      let arrUrls=context.rootState.settings.couchdbUrls.slice()
      // *** if a url is specified, favor it.
      if(opts.url){
        arrUrls.splice(0,0,opts.url) //insert the desired at first place, so if respond, will be the first.
      }
      // *** all urls are checked
      arrUrls.map((x,i)=>{
        //https://developer.mozilla.org/fr/docs/Web/JavaScript/Guide/Utiliser_les_promesses
        const wait = ms => new Promise(resolve => setTimeout(resolve, ms));
        //add a timeout, so we prioritize the first one.
        arrAxios.push(wait(1000*i).then(()=>axios.get('_session',{baseURL:x})))
      })
      // get the first that resolve
      return utils_gen.PromiseFirstResolve(arrAxios).then(rep=>{ // this will take the first that resolve. in case many server available, the faster should respond first... most case, only one willl be available
        context.state.couchdbUrl=rep.config.baseURL
        axios.defaults.baseURL=rep.config.baseURL
        // TODO: Verify if we should place some place else - but should always work (current assumption)
        return context.dispatch('form/setRemoteDB') // have to update the current remote DB, in case the url changed
      }).then(()=>{
        return Promise.resolve()
      }).catch(err=>{
        context.dispatch('app_message_error',err,{root:true})
        return Promise.reject()
      })
    },
    verifySession(context, opts){
      let db =null
      return context.dispatch('sqlApi/login',{}).then(()=>{
        return context.dispatch('setCouchDBUrl')
      }).then(rep=>{
        db = new PouchDB(context.getters.databaseSystem, context.getters.pouchDbOptions)
        return axios.get(context.getters.sessionUrl, {headers: {'Authorization': "Bearer " + localStorage.getItem('user-token-couchdb')}})
      }).then(response=>{
        //console.log(response)
        const rep2 = response.data
        //TODO: check for a valid user, for now, it's null if we have a response.
        if (rep2.userCtx.name) {
          //has it changed:
          if(context.state.userName!=rep2.userCtx.name || context.state.dbDatabaseSystem.name!=db.name){
            context.state.session=rep2
            return context.dispatch('login',{userName:rep2.userCtx.name,db:db}).then(rep=>{
              //login is done, save/update local username info - so available offline
              return context.dispatch('localDB/setLocalUserName',rep2.userCtx)
            })
          }
          return Promise.resolve()
        }
        return Promise.reject()
      }).catch(err=>{
        context.state.session=null
        context.commit('login',{userName:null})
        console.warn(err)
        if (opts && opts.noError){
          return Promise.resolve() // we resolve as we want to be able to work offline.
        }else{
          return Promise.reject(err) // we resolve as we want to be able to work offline.
        }
      })
    },
    login(context,opt){
      context.commit('login',opt)
      if(context.state.sessionTimer===null){
        //firt time, set a timer to check the session:
        var a1=function(){
          context.dispatch('verifySession')
        }
        context.state.sessionTimer=setInterval(a1,1000*60*5) // each 5 minutes, so we get a new cookie.
      }
      return context.dispatch('getSystemDoc')
    },
    logout(context){
      return context.dispatch('sqlApi/logout',{}).then(re =>{
        clearInterval(context.state.sessionTimer) // stop checking session.
        context.commit('login',{userName:null})
        return Promise.resolve()
      })
    },
    getSystemDoc(context){
      if(context.state.dbDatabaseSystem){
        let db=context.state.dbDatabaseSystem
        return db.get('config').then(doc=>{
          context.commit('systemDoc',doc)
          return Promise.resolve()
        }).catch(err=>{
          if (err.reason == "Database does not exist."){
            // TODO: create it 
          }
          context.dispatch('app_message_error',err,{root:true})
          return Promise.reject(err)
        })
      }
      return Promise.reject()
    },
    getConfigDoc(context){
      const db=context.state.dbDatabaseSystem
      //read the definition content (uploaded file)
      return db.get('config').then(doc=>{
        return Promise.resolve(doc)
      }).catch(err=>{
        context.dispatch('app_message_error',err,{root:true})
        return Promise.reject(err)
      })
    },
    replicateDB(context,id){
      // verify if we already have a replication model:
      const db=new PouchDB(context.state.couchdbUrl + '_replicator', context.getters.pouchDbOptions)
      return db.get('replicate_' + context.state.settings.databaseSystem).then(doc=>{
        let replicateDocs=[]
        replicateDocs.push(makeReplicateDoc(id,doc,false))
        replicateDocs.push(makeReplicateDoc(id,doc,true))
        return db.bulkDocs(replicateDocs)
      }).then(()=>{
        // copy the security doc when available... how? timer? vs auth...
        // TODO: Copy security doc
        return Promise.resolve()
      }).catch(err=> {
        context.dispatch('app_message_error',err,{root:true})
        return Promise.resolve()
      })
    },
    app_message(context,app_message){
      context.state.app_messages.push(app_message)
      if(context.state.save_app_messages){
        context.dispatch('app_message_getDoc', true).then(doc=>{
          // build date time for this message:
          const dt = moment().utc().format()
          doc.messages.push({date: context.getters.utcCurrentTime(), message:app_message, user: context.getters.userName})
          return context.rootState.localDB.db.put(doc)
        }).catch(err=>{
          this.mess = err
          this.$store.dispatch('app_message_error',err)
        })
      }
    },
    app_message_error(context,app_message){
      // log to the console the error and to the app_message
      console.error(app_message)
      context.state.app_messages.push(app_message)
      if(context.state.save_app_messages){
        context.dispatch('app_message_getDoc', true).then(doc=>{
          // build date time for this message:
          doc.error_messages.push({date: context.getters.utcCurrentTime(), message:app_message, user: context.getters.userName})
          return context.rootState.localDB.db.put(doc)
        }).catch(err=>{
          this.mess = err
          this.$store.dispatch('app_message_error',err)
        })
      }
    },
    app_message_getDoc(context, forceCreation=false){
      // TODO: one coducment per day, with all the messages of the day. but harder to sync...
      // const docId = 'app_messages_' + moment().utc().format('YYYY-MM-DD')
      const docName = context.rootState.localDB.config.device_id + '_app_messages'
      return context.rootState.localDB.db.get(docName).then(doc=>{
        // console.log(doc);
        // doc already exists
        return Promise.resolve(doc)
      }).catch(err=>{
        //doc don't exists
        if (forceCreation==false){
          return Promise.resolve(null)
        }
        let obj1={_id: docName, messages:[], error_messages:[]}
        return Promise.resolve(obj1)
      })
    },
    app_messsages_sync(context){
      // get the app_messages doc
      context.dispatch('app_message_getDoc').then(doc=>{
        //sync the doc to the master config DB
        if (doc){
          return context.rootState.localDB.db.replicate.to(context.rootState.dbDatabaseSystem, Object.assign({doc_ids:[doc._id]}, context.getters.pouchDbOptions))
        }
        return Promise.resolve(null)
      }).catch(err=>{
        context.dispatch('app_message_error',err)
      })
    }
  }
})

const makeReplicateDoc=(id,model,reverse)=>{
  const rep = JSON.parse(JSON.stringify(model))
  rep._id= 'replicate_' + id + (reverse?'_r':'')
  delete rep._rev
  if(reverse){
    rep.source.url=model.target.url.substring(0,model.target.url.lastIndexOf('/') + 1) + id
    rep.target.url=model.source.url.substring(0,model.source.url.lastIndexOf('/') + 1) + id
  }else{
    rep.source.url=model.source.url.substring(0,model.source.url.lastIndexOf('/') + 1) + id
    rep.target.url=model.target.url.substring(0,model.target.url.lastIndexOf('/') + 1) + id
  }
  return rep
}
